import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';

interface SectionProps {
  children: ReactNode;
  direction?: 'left' | 'right';
}

const Section: React.FC<SectionProps> = ({ children, direction = 'left' }) => {
  const initialX = direction === 'right' ? 50 : -50;
  const exitX = direction === 'right' ? -50 : 50;

  return (
    <motion.div
      initial={{ opacity: 0, x: initialX }} // Initial animation values
      animate={{ opacity: 1, x: 0 }} // Animation when the section enters the viewport
      exit={{ opacity: 0, x: exitX }} // Animation when the section leaves the viewport
      transition={{ duration: 0.5 }} // Animation duration
    >
      {children}
    </motion.div>
  );
};

export default Section;
