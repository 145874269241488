import React from 'react';

interface CustomIconProps {
  filled?: boolean;
  color?: string;
}

const GoogleTranslateIcon: React.FC<CustomIconProps> = ({ color }) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.822 3.19038H8.14196L7.4009 0.664062H2.34827C1.90159 0.664063 1.47321 0.841506 1.15736 1.15736C0.841506 1.47321 0.664063 1.90159 0.664062 2.34827V13.2956C0.664063 13.7423 0.841506 14.1707 1.15736 14.4866C1.47321 14.8024 1.90159 14.9799 2.34827 14.9799H8.24301L9.08512 17.5062H15.822C16.2686 17.5062 16.697 17.3287 17.0129 17.0129C17.3287 16.697 17.5062 16.2686 17.5062 15.822V4.87459C17.5062 4.42791 17.3287 3.99952 17.0129 3.68367C16.697 3.36782 16.2686 3.19038 15.822 3.19038ZM5.01775 11.2662C4.10429 11.2662 3.22824 10.9033 2.58232 10.2574C1.93641 9.61147 1.57354 8.73542 1.57354 7.82196C1.57354 6.9085 1.93641 6.03245 2.58232 5.38653C3.22824 4.74062 4.10429 4.37775 5.01775 4.37775C5.89354 4.37775 6.69354 4.68933 7.32512 5.29564L7.4009 5.32933L6.34827 6.32301L6.29775 6.2809C6.05354 6.05354 5.6409 5.78406 5.01775 5.78406C3.91459 5.78406 3.01354 6.70196 3.01354 7.82196C3.01354 8.94196 3.91459 9.85985 5.01775 9.85985C6.17143 9.85985 6.66827 9.12722 6.80301 8.63038H4.94196V7.32512H8.26827L8.27669 7.4009C8.31038 7.5609 8.3188 7.7209 8.3188 7.89775C8.3188 9.87669 6.97985 11.2662 5.01775 11.2662ZM10.0956 9.82617C10.3735 10.3483 10.7188 10.8199 11.0977 11.2577L10.643 11.7041L10.0956 9.82617ZM10.7441 9.18617H9.92722L9.64933 8.31038H13.0093C13.0093 8.31038 12.723 9.41354 11.6956 10.6177C11.2577 10.0956 10.9462 9.58196 10.7441 9.18617ZM16.6641 15.822C16.6641 16.0453 16.5753 16.2595 16.4174 16.4174C16.2595 16.5753 16.0453 16.6641 15.822 16.6641H9.92722L11.6114 14.9799L10.9293 12.6472L11.7041 11.8725L13.9609 14.1377L14.5588 13.523L12.2935 11.2662C13.0514 10.3988 13.6409 9.37143 13.9104 8.31038H14.9799V7.43459H11.9146V6.5588H11.0388V7.43459H9.38827L8.39459 4.03248H15.822C16.0453 4.03248 16.2595 4.12121 16.4174 4.27913C16.5753 4.43706 16.6641 4.65125 16.6641 4.87459V15.822Z'
      fill={color ?? '#AEAEAE'}
    />
  </svg>
);

export default GoogleTranslateIcon;
